<!-- =========================================================================================
  File Name: UserList.vue
  Description: User List page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <v-card>
    <!-- HEADER TOOLBAR -->
    <v-toolbar class="page-primary-card-header">
      <v-list-item dark>
        <v-list-item-avatar color="primary lighten-2">
          <v-icon>{{ card_header_props.header.icon }}</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="headline">{{
            card_header_props.header.headLine
          }}</v-list-item-title>
          <v-list-item-subtitle>{{
            card_header_props.header.subTitle
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-toolbar>
    <!-- HEADER TOOLBAR -->
    <div class="p-5">
      <user-list />
    </div>
  </v-card>
</template>

<script>
import UserList from "./user-list/UserApproveList.vue";
require("../../../../assets/css/neo.css");

export default {
  components: {
    UserList,
  },
  data() {
    return {
      card_header_props: {
        header: {
          headLine: this.$t("_user_management.User_Approval"),
          subTitle: this.$t("_user_management.Subtitle2"),
          icon: "mdi-account-multiple",
        },
      },
    };
  },
};
</script>
