<template>
  <div id="page-user-list">
    <div class="vx-card p-6">
      <div class="flex flex-wrap items-center mb-4">
        <v-text-field
          v-model="searchText"
          label="Search"
          style="max-width: 250px"
          outlined
          hide-details
          dense
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          @click="deleteSelectedUsers"
          :disabled="selecteds.length === 0"
          class="v-step-remove-user mr-2"
          >{{ this.$t("_user_management.Delete") }}</v-btn
        >
        <v-btn
          color="success"
          @click="approveSelectedUsers"
          :disabled="selecteds.length === 0"
          class="v-step-remove-user mr-2"
          >{{ this.$t("_user_management.Approve") }}</v-btn
        >
      </div>
      <v-data-table
        :headers="headers"
        :items="usersData"
        :options.sync="options"
        :server-items-length="totalCount"
        v-model="selecteds"
        show-select
        item-key="Id"
      >
      </v-data-table>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import vSelect from "vue-select";
import moduleUserManagement from "@/store/user-management/moduleUserManagement.js";

export default {
  name: "user-management",
  components: {
    AgGridVue,
    vSelect,
  },
  data() {
    return {
      headers: [
        {
          text: this.$t("_user_management.Username"),
          value: "UserName",
        },
        {
          text: this.$t("_user_management.Email"),
          value: "Email",
        },
        {
          text: this.$t("_user_management.Name"),
          value: "FirstName",
        },
        {
          text: this.$t("_user_management.Last_Name"),
          value: "LastName",
        },
      ],
      options: {},
      totalCount: 0,
      selecteds: [],
      usersData: [],
      searchText: null,
    };
  },
  watch: {
    options: {
      handler() {
        this.loadUsers();
      },
      deep: true,
    },
    searchText() {
      this.loadUsers();
    },
  },
  methods: {
    getLangText(_text) {
      return this.$goc.langHelper.getLangText(_text);
    },
    showSuccess(isDelete = true) {
      this.$set(this, "options.page", 0);
      this.loadUsers();
      this.$goc.notify.success({
        title: this.$t("_common.Success"),
        message: this.$t(
          isDelete
            ? "_user_management.UserRemovedSuccessfuly"
            : "_user_management.UserApprovedSuccessfuly"
        ),
      });
    },
    deleteSelectedUsers() {
      let currentUser = JSON.parse(localStorage.getItem("userInfo"));

      if (this.selecteds.includes(currentUser.Id)) {
        this.$goc.notify.warning({
          title: this.$t("_user_management.Restricted_Operation"),
          message: this.$t("_user_management.You_can_not_delete_yourself"),
        });
        return;
      }

      this.$goc.notify.confirm(
        {
          message:
            this.$t("_common.AreYouSure") +
            " " +
            this.selecteds.length +
            this.$t("_user_management.Selected_record_will_be_removed"),
        },
        (res) => {
          if (res) {
            let idList = this.selecteds.map((user) => user.Id);

            this.$goc.request.post(
              this.$goc.ENUMS.API.DeleteUnapprovedUser,
              { idList },
              {
                then: (res) => {
                  this.showSuccess();
                },
                exception: () => {
                  this.$goc.notify.warning({
                    title: this.$t("_user_management.Restricted_Operation"),
                    message: this.$t(
                      "_user_management.You_can_not_delete_yourself"
                    ),
                  });
                },
              }
            );
          }
        }
      );
    },
    approveSelectedUsers() {
      let currentUser = JSON.parse(localStorage.getItem("userInfo"));

      if (this.selecteds.includes(currentUser.Id)) {
        this.$goc.notify.warning({
          title: this.$t("_user_management.Restricted_Operation"),
          message: this.$t("_user_management.You_can_not_approve_yourself"),
        });
        return;
      }

      this.$goc.notify.confirm(
        {
          message:
            this.$t("_common.AreYouSure") +
            " " +
            this.selecteds.length +
            this.$t("_user_management.Selected_record_will_be_approved"),
        },
        (res) => {
          if (res) {
            let idList = this.selecteds.map((user) => user.Id);

            this.$goc.request.post(
              this.$goc.ENUMS.API.ApproveUnapprovedUser,
              { idList },
              {
                then: (res) => {
                  this.showSuccess(false);
                },
                exception: () => {
                  this.$goc.notify.warning({
                    title: this.$t("_user_management.Restricted_Operation"),
                    message: this.$t(
                      "_user_management.You_can_not_approve_yourself"
                    ),
                  });
                },
              }
            );
          }
        }
      );
    },
    loadUsers() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      this.$goc.request.get(
        this.$goc.ENUMS.API.GetUnapprovedUsers,
        (result) => {
          if (result.IsSuccess === true) {
            this.usersData = result.Result.UnapprovedUserList;
            this.totalCount = result.Result.Count;
          }
        },
        {
          offset: page > 1 ? (itemsPerPage - 1) * page : 0,
          limit: itemsPerPage,
          sortDesc: sortDesc[0],
          sortBy: sortBy[0],
          searchText: this.searchText,
        }
      );
    },
  },
  mounted() {
    this.$goc.setModule("UserList", this);
    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector(
        ".ag-header-container"
      );
      header.style.left =
        "-" + String(Number(header.style.transform.slice(11, -3)) + 9) + "px";
    }
  },
  created() {
    if (!moduleUserManagement.isRegistered) {
      this.$store.registerModule("userManagement", moduleUserManagement);
      moduleUserManagement.isRegistered = true;
    }
  },
};
</script>

<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
